const quiz = [
  {
    questionText: "Choose the correct translation: 'What is your name?'",
    answerOptions: [
      { answerText: "Tôi khoẻ", isCorrect: false },
      { answerText: "Không tốt lắm", isCorrect: false },
      { answerText: "Bạn tên gì", isCorrect: true },
      { answerText: "Rất vui được gặp bạn", isCorrect: false },
    ],
  },
  {
    questionText: "How to reply: 'Bạn khoẻ không?'",
    answerOptions: [
      { answerText: "Tôi là Lan", isCorrect: false },
      { answerText: "Tôi khoẻ. Còn bạn?", isCorrect: true },
      { answerText: "Rất vui được gặp bạn", isCorrect: false },
      { answerText: "Chào", isCorrect: false },
    ],
  },
  {
    questionText: "Which answer is correct for 'Bạn tên gì?'",
    answerOptions: [
      { answerText: "Cảm ơn", isCorrect: false },
      { answerText: "Không khoẻ lắm", isCorrect: false },
      { answerText: "Hẹn gặp lại", isCorrect: false },
      { answerText: "Em là Nga", isCorrect: true },
    ],
  },
]

export default quiz
