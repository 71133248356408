import { createTheme } from "@mui/material/styles"

const muiTheme = createTheme({
  root: {
    "& > *": {
      justifyContent: "center",
      display: "flex",
    },
  },
  palette: {
    primary: {
      main: "#2980b9",
    },
    secondary: {
      main: "#404040",
    },
    text: {
      primary: "#050505",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: `"Lora", Georgia, "Times New Roman", serif`,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1.15rem",
      letterSpacing: "0.1rem",
    },
  },
})

export default muiTheme
